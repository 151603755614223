/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

// Alpine JS
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';

// Imports
import Flickity from 'flickity';
import htmx from 'htmx.org';
import imagesLoaded from 'imagesloaded';
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';

// Project Imports
import env from '@js/utils/env';
import utils from '@js/utils/utils';
import Signal from '@js/classes/Signal';
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/VhCalc';
import VideosLoaded from '@js/helpers/VideosLoaded';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import NavActions from '@js/modules/NavActions';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import CookieMessage from '@js/modules/CookieMessage';
import ScrollTo from '@js/modules/ScrollTo';
import SubNavHover from '@js/modules/SubNavHover';

// Alpine
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.start();

window.SYSTEM =
    window.SYSTEM ||
    new (function SYSTEM() {
        // ENV
        this.env = env;

        // Utilities
        this.utils = utils;

        // Classes
        this.classes = {
            Signal: Signal
        };

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            ScrollTo: ScrollTo
        };

        // Check to run animations
        if (!this.env.reducedMotion) {
            Appear.start();
            AppearParent.start();
        }

        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            /**
             * Lazy load
             */
            let lazyLoadInstance = new LazyLoad({
                threshold: 400
            });

            /**
             * Reframe
             */
            reframe('.reframe');

            /**
             * Sub nav hover
             */
            SubNavHover.init();

            /**
             * Required asterisk in placeholder
             */
            const requiredInputs = document.querySelectorAll('input[required], textarea[required]');
            // Loop through each required input field
            requiredInputs.forEach(input => {
                // Append a * to the placeholder
                input.placeholder += ' *';
            });

            /**
             * Carousels
             */
            const carouselEls = document.querySelectorAll('.carousel:not(._sprig)');
            carouselEls.forEach((el) => {
                new VideosLoaded(el, (videos) => {
                    let imgsLoad = imagesLoaded(el);

                    function onAlways(instance) {
                        let flkty = new Flickity(el, {
                            cellAlign: 'left',
                            wrapAround: true,
                            pageDots: false,
                            arrowShape: 'M51.6 100 .3 49.9 51.5 0 58 6.7 18.4 45.3h81.9v9.3H18.4l39.8 38.7z'
                        });
                    }

                    imgsLoad.on('always', onAlways);
                });
            });
        });

        htmx.on('htmx:afterSwap', (event) => {
            const targetId = event.target.id;

            if (targetId === 'work-entry-related') {
                const element = document.getElementById('work-entry-related');
                const carouselEls = element.querySelectorAll('.carousel');

                carouselEls.forEach((el) => {
                    new VideosLoaded(el, (videos) => {
                        let imgsLoad = imagesLoaded(el);

                        function onAlways(instance) {
                            let flkty = new Flickity(el, {
                                cellAlign: 'left',
                                wrapAround: true,
                                pageDots: false,
                                arrowShape: 'M51.6 100 .3 49.9 51.5 0 58 6.7 18.4 45.3h81.9v9.3H18.4l39.8 38.7z'
                            });
                        }

                        imgsLoad.on('always', onAlways);
                    });
                });
            }
        });

    })();
