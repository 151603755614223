class CookieMessage {
    constructor() {
        // Output message
        if (typeof config !== 'undefined') {
            if (config.showCookieMessage) {
                this.addCookieMessage(config.cookieMessage);
            }
        }
    }

    addCookieMessage(message) {
        // Check to see if localstorage item exists
        if (!localStorage.getItem('__cookie-message')) {
            // Add the cookie message to the DOM
            const container = document.createElement('div');

            container.innerHTML = `
                <div class="cookie-message">
                    <div class="container">
                        <div class="cookie-message-content">
                            <div>
                                ${message}
                            </div>

                            <div>
                                <button id="cookie-message-confirm" class="button" type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            `;
            document.body.appendChild(container);

            // Set localstorage item
            localStorage.setItem('__cookie-message', 1);

            // Add event listener to remove the cookie message and set the cookie
            const confirmBtn = document.getElementById('cookie-message-confirm');
            confirmBtn.addEventListener('click', () => {
                container.remove();
            });
        }
    }
}

export default new CookieMessage();
