class SubNavHover {
    constructor() {
    }

    init() {
        // Add hover event listeners to elements with data-sub-nav attribute
        document.querySelectorAll('[data-sub-nav]').forEach(element => {
            element.addEventListener('mouseenter', this.showSubNav.bind(this));
            element.addEventListener('mouseleave', this.hideSubNav.bind(this));
        });
    }

    showSubNav(event) {
        const element = event.currentTarget;
        const attrValue = element.getAttribute('data-sub-nav');
        const subNavElement = document.getElementById(`sub-nav-${attrValue}`);

        if (subNavElement) {
            // Position the sub-nav element in the middle of the hovered element
            const rect = element.getBoundingClientRect();
            const topPosition = rect.top + (rect.height / 2) - (subNavElement.offsetHeight / 2);
            subNavElement.style.top = `${topPosition}px`;
            subNavElement.style.left = `${rect.left}px`; // Position it to the right of the hovered element

            // Show the sub-nav element
            subNavElement.style.display = 'block';

            // Add a class to keep the sub-nav visible when hovering over it
            subNavElement.addEventListener('mouseenter', this.keepSubNavVisible.bind(this));
            subNavElement.addEventListener('mouseleave', this.hideSubNav.bind(this));
        }
    }

    hideSubNav(event) {
        const element = event.currentTarget;

        if (element.hasAttribute('data-sub-nav')) {
            const attrValue = element.getAttribute('data-sub-nav');
            const subNavElement = document.getElementById(`sub-nav-${attrValue}`);
            if (subNavElement) {
                subNavElement.style.display = 'none';
            }
        }
        else {
            element.style.display = 'none';
        }
    }

    keepSubNavVisible(event) {
        event.currentTarget.style.display = 'block';
    }
}

export default new SubNavHover();
